import React, { Component, useState, useEffect } from "react";
import "./Signin.css";
import { API_URL } from "../../config";
import SignIn from "../../image/sign-In-screen.png";
import logo from "../../image/logo.png";
import { publicApi } from "../../api/publicApi";
import { getFinalHostName } from "../../utils/helpar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Signin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [hedarImage, setHedar] = useState("");
  const [dealerId, setdealerId] = useState("");
  const hostname = window.location.hostname;

  const finalHostName = getFinalHostName(hostname);

  const fetchDealerDetails = async () => {
    try {
      const res = await publicApi.getDealerDeail({ domain: finalHostName });
      setHedar(res.data[0].logo_path_header);
      setdealerId(res.data[0].dealer_id);
    } catch (error) {
      console.log("Error:", error.message);
    }
  };

  useEffect(() => {
    console.log("calling fetch detauils api fo");
    fetchDealerDetails();
  }, []);

  const handleChange = (e) => {};

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!username || !password) {
      toast.error("Please check your username and password", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable:   
       true,
        progress: undefined,   
      
      });
    }
    fetch(API_URL + "/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: username,
        password: password,
        dealerId: dealerId,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.status) {
          localStorage.setItem("accessToken", res?.token?.accessToken);
          localStorage.setItem("dealerId", res?.info?.dealerId);
          localStorage.setItem("logo", res?.info?.logo);

          localStorage.setItem("loginstatus", true);
          localStorage.setItem("email", username);
          localStorage.setItem("userid", res?.info?.id);
          window.location.href = "/";
        } else {
          toast.error(res.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable:   
           true,
            progress: undefined,   
          
          });
        }
      });
    return;
  };



  return (
    <div className="container-first">
      <div className="container-serials">
        <div className="container-second">
          <div className="first-container">
            <img src={SignIn} alt="SignIn" loading="eager" />
          </div>

          <div className="second-container">
            <form onSubmit={handleSubmit}>
              <div className="hadle-site">
                <div className="logo-png">
                  {hedarImage ? (
                    <img src={hedarImage} alt="motor-logo" width={250} />
                  ) : (
                    <img src={logo} alt="default-logo" width={250} />
                  )}
                </div>

                <h1 className="heding-sign-in">Sign in </h1>
                <p className="para-one">
                  Enter your email and password to sign in!
                </p>
                <div className="text_area">
                  <div className="label-second">
                    <label>
                      Email <span className="span-class">*</span>
                    </label>
                  </div>
                  <input
                    type="text"
                    id="username"
                    name="Email"
                    defaultValue=""
                    className="text_input"
                    placeholder="Email"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div>
                  <div className="label-second">
                    <label>
                      Password <span className="span-class">*</span>
                    </label>
                  </div>

                  <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder="password"
                    className="text_input"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
              <input type="submit" value="LOGIN" className="btn" />
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
    
  );
};

export default Signin;
